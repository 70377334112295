.App {
  text-align: center;
}

header {
  margin-bottom: 20px;
}

.status {
  font-size: 26px;
  font-weight: bold;
}

.totals {
  margin: 20px 0;
}

.total-item {
  margin-bottom: 10px;
}

.total-item .label {
  font-weight: bold;
  
}

.total-item .value {
  font-size: 18px;
}

.additional-info {
  margin: 20px 0;
}

.info-item {
  margin-bottom: 10px;
}

.info-item .label {
  font-weight: bold;
}

.info-item .value {
  font-size: 16px;
}

.stale-data {
  color: red;
  font-weight: bold;
}

table {
  margin: 0 auto;
  border-collapse: collapse;
  width: auto;
  max-width: 100%;
  overflow-x: auto;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.red-background {
  background-color: #f8d7da;
}

.green-background {
  background-color: #d4edda;
}

.default-background {
  background-color: #ffffff;
}

.table-container {
  width: auto;
  margin: 0 auto;
  display: inline-block;
  max-width: 90%;
}

.subscribe-link a {
  text-decoration: none;
}

.subscribe-box {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #0088cc;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 25px;
  transition: background-color 0.3s ease;
}

.subscribe-box:hover {
  background-color: #006699;
}

.telegram-logo {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.info-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  
}

.logo-container {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.logo {
  width: 100px;
  height: auto;
}

.value-container {
  display: flex;
  align-items: center;
}

.value {
  vertical-align: middle;
}

.additional-info h2 {
  font-size: 24px;
  margin-bottom: 10px;
  
}

.additional-info h2 abbr {
  position: relative;
  cursor: help;
  text-decoration: underline;
  text-decoration-style: dotted;
}

.additional-info h2 abbr::after {
  content: "?";
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background-color: #000;
  border-radius: 50%;
  padding: 2px 6px;
  margin-left: 4px;
}

.additional-info h2 abbr:hover::before {
  content: attr(data-title);
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f5f5f5;
  color: #000;
  padding: 10px;
  border-radius: 8px;
  font-size: 14px;
  max-width: 400px;
  width: max-content;
  z-index: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.table-container {
  overflow-x: auto;
}

.responsive-table {
  width: 100%;
  border-collapse: collapse;
}

.responsive-table th,
.responsive-table td {
  padding: 8px;
  text-align: left;
}

@media screen and (max-width: 600px) {
  .responsive-table thead {
    display: none;
  }

  .responsive-table tbody tr {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .responsive-table tbody td {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    text-align: right;
  }

  .responsive-table tbody td:before {
    content: attr(data-label);
    font-weight: bold;
    margin-right: 10px;
  }

  .responsive-table tbody td[data-label="Address"] {
    font-weight: bold;
    text-align: left;
    margin-bottom: 5px;
  }

  .responsive-table tbody td[data-label="Address"]:before {
    content: "";
    margin-right: 0;
  }
}